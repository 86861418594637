html {
  --page-margin: #{$page-margin};
  --min-column: #{$min-column};
  --column-gap: #{$column-gap};
  --photo-gap: #{$photo-gap};
}

html,body {
  @include body-font;
  color: $dark;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

html,body,#root {
  width:100%;
  height:100%;
  overflow: hidden;
}

p {
  font-size: 1.2rem;
}

h1, h2, h3, h4, h5, h6 {
  @include header-font;
  margin-block-start: 0;
}
//To keep anchor linked content from being covered by sticky nav
.WeddingSite {
  --nav-height: 5.125rem;
  --mobile-offset: 7rem;

  width:100%;
  height:100%;
  overflow: auto;
  &.frozen {
    overflow: hidden;
  }


  a[id] {
    display: block;
    position: relative;
    top: calc(var(--nav-height) * -1);
  }


  .navigation {
    box-shadow: 0 0.5rem 0.5rem 0 rgba($dark,0.05);

    display: grid;
    grid-auto-flow: row;
    align-items: center;
    height: var(--nav-height);
    background: $light;
    position: sticky;
    top: -1px;
    white-space:nowrap;

    header {
      padding: 0.5rem $page-margin 0;
      text-align: center;
      h1 {
        margin: 0;
        font-size: 1.5rem;
      }
    }
    nav {

      padding: 0.5rem $page-margin 0.5rem;
      text-align: center;
      overflow-x: auto;
      font-size: 1.25rem;
      a {
        margin-inline-end: 1rem;
        &:last-child {
          margin-inline-end: 0;
        } 
      }
    }
  }

  .TitleSlide {
    --slide-height: calc(100vh - var(--nav-height) - var(--mobile-offset));
    width: 100%;
    height: var(--slide-height);
    display: grid;
    grid-template-rows: 1fr 1fr;
    place-items: center;
    margin: 0;
    text-align: center;

    &:after{
      content:"";
      position:fixed; /* stretch a fixed position to the whole screen */
      top:0;
      height: var(--slide-height); /* fix for mobile browser address bar appearing disappearing */
      left:0;
      right:0;
      z-index:-1; /* needed to keep in the background */
      background: url("A+A-9.jpg") center center;
      background-size: cover;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
    }
    div {
      display: grid;
      place-items: center;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(20, 22, 19, 0.75) 0%, rgba(20, 22, 19, 0) 100%);
    }
  
    h1, h2, h3 {
      color: #fff;
      margin:0;
    }
    h1 {
      font-size: 4.5rem;
      line-height: 5.5rem;
    }
    h2 {
      font-size: 1.5rem;
      line-height: 2.5rem;
      
    }
  }

  .WeddingStory {
    background: $green;
    padding: calc(var(--nav-height) + 1rem) $page-margin;
    text-align: left;
    

    *:first-child {
      margin-block-start: 0;
    }

    h1 {
      grid-area: title;
      width: 12rem;
    }
    article {
      grid-area: article;
      columns: 3 $min-column;
      column-gap: $column-gap;
    }
  }

  .Gallery {
    background: $light;

    padding: calc(var(--nav-height) + 1rem) $page-margin 0;

    & > ul {
      margin-block-start: 0;
      
      list-style-type: none;
      padding: 0;

      columns: 2 $min-column/2;
      column-gap: $photo-gap;
      align-content: start;
      margin-block-end: 0;
      li { 
        margin-bottom: $photo-gap;
        img {
          display: block;
          width: 100%;
        }
      }
    }

    .Viewer {
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;
      position: fixed;
      top: 0;
      height: 100%;
      left: 0;
      width: 100%;
      overflow: hidden;
      display: grid;
      grid-template-rows: auto 1fr 2rem;
      background: rgba($light, 0.85);
      backdrop-filter: blur(16px);

      &.open {
        opacity: 1;
      }

      &.closed {
        opacity: 0;
        display:none;

      }

      .toolbar {
        padding: 0.25rem;
        text-align: right;

        button {
          appearance: none;
          border: none;
          font-size: 2rem;
          line-height: 1rem;
          padding: 0.5rem;
          background: none;
          border-radius: 50%;

          &:hover {
            background: rgba($dark, 0.1);
          }
        }
      }

      .photo {
        display: block;
        position: relative;
        .slide {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;

          .pic {
            position: relative;
            top: 0;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
          }
        }
        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
      }

      .reel {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: $light;

        overflow-y: hidden;
        overflow-x: auto;
        & > ul {
          height: 2rem;
          padding: 0.125rem 0;
          margin: 0;
          list-style-type: none;
          display: block;
          white-space: nowrap;



          &:before, &:after {
            content:"";
            display: inline-block;
            width: 50vw;
          }

          li {
            overflow: hidden;
            display: inline-grid;
            place-content: center;
            max-width: 1rem;
            height: 2rem;
            margin: 0 1px;

            transition-property: max-width, margin;
            transition-duration: 1s, 1s;
            transition-timing-function: linear, linear;
            transform: translateZ(0);

            &.active {
              max-width: 4rem;
              margin: 0 1rem;
              //transition: width 1s ease-in-out;//, margin 0.25s ease-in-out;
  
            }

            img {
              height: 2rem;
              &:hover {
                opacity:0.75;
              }
            }
          }

        }
      }
    }
  }


  .Video {
    background: $light;
    padding: calc(var(--nav-height) + 1rem) $page-margin;

  }

  .Registry {
    background: $green;
    padding: calc(var(--nav-height) + 1rem) $page-margin;
    text-align: left;
    
    a.button {
      border-radius: 0.25rem;
      display: inline-block;
      background: $button;
      color: $white; 
      padding: 0.75rem 1.5rem;

      &:hover {
        background:#536A76;
      }
    }

    *:first-child {
      margin-block-start: 0;
    }

    h1 {
      grid-area: title;
      width: 12rem;
    }
  }

  .Thanks {
    padding: calc(var(--nav-height) + 1rem) $page-margin;
    background: $light;
    
    *:first-child {
      margin-block-start: 0;
    }

    h1 {
      font-size: 3rem;
      text-align: center;
    }

    h3 {
      margin: 0;
      font-size: 1rem;
    }

    a {
      color: $link;
    }

    li {
      margin-block-end: 1rem;
    }

    main {
      display: grid;
      gap: $column-gap;
      grid-template-columns: repeat(auto-fit, minmax($min-column,1fr));
    }
  }
  .OurStory {
    padding: 20px;
    text-align: left;;
  }
  .EndSlide {
    width: 100%;
    height: calc(100vh - var(--nav-height));
    background-image: url("A+A-12.jpg");
    background-position: center center;
    background-size: cover;
    margin: 0;
    color: $white;
    @include header-font;
  }
  


}


@media (hover: none) {
  .WeddingSite .Gallery .reel > ul li img:hover{
    opacity: 1;
  }
}