$white: #fff;
$light: #F7F3F0;
$green: #B3BEB9;
$button: #333C41;
$button-hover: #536A76;
$dark: #030C0B;
$link: blue;

$page-margin: 1.5rem;
$min-column: 16rem;
$column-gap: 1.5rem;
$photo-gap: 1rem;

@mixin header-font {
  font-family: "Playfair Display", serif;
  font-style: italic;
  font-weight: bold;
}
@mixin body-font {
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 300;
}

a[href] {
  color: #3b4248;
  text-decoration: none;
  &:hover, &:focus {
    color: $dark;
    text-decoration: underline;
  }
}