@media only screen and (min-width: 50rem) {
  $page-margin: 4rem;
  $column-gap: 4rem;

  @import "./mobile";

  p {
    font-size: 1rem;
  }

  .WeddingSite {
    --nav-height: 4rem;
    --mobile-offset: 0rem;

    .navigation {
      grid-auto-flow: column;

      header {
        padding: 0.25rem $page-margin;
        text-align: left;
      }
      nav {
        padding: 0.25rem $page-margin;
        text-align: right;
      }
    }

    .TitleSlide {
      h1 {
        font-size: 6rem;
        line-height: 10rem;

      }
    }

    .WeddingStory {
      display: grid;
      /*
      grid-template-columns: max-content 1fr minmax($min-column, (($min-column * 3 * 1.5) + ($column-gap*2))) 1fr;
      grid-template-areas: "title . article .";
      */
      grid-template-columns: 12rem 1fr;
      grid-template-areas: "title article";
      gap: $column-gap;

      h1:first-child {
        margin-block-start: -0.5rem;
      }
    }

    .Gallery {
      & > ul {
        columns: 4 $min-column;
      }

      .Viewer {
        grid-template-rows: auto 1fr 2rem;

      }
    }
    .Registry {
      display: grid;
      /*
      grid-template-columns: max-content 1fr minmax($min-column, (($min-column * 3 * 1.5) + ($column-gap*2))) 1fr;
      grid-template-areas: "title . article .";
      */
      grid-template-columns: 12rem 1fr;
      grid-template-areas: "title article";
      gap: $column-gap;

      h1:first-child {
        margin-block-start: -0.5rem;
      }
    }
  }
} 